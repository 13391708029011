.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  width: 100%;
  z-index: 1017;
  background:"green";
  /* background: #850c62; */
  /* background: linear-gradient(90deg, #850c62, #f80759); */
  padding: 1em;
  color: #fff;
  padding-top: 20px;
  padding-bottom:  20px ;
}
.menu {
  padding-left: 0;
}

.menu a {
  /* color: #850c62; */
  font-weight: 800;
  /* text-transform: uppercase; */
}

.footer {
  width: 100%;
  z-index: 1017;
  background: #850c62;
  background: linear-gradient(90deg, #850c62, #f80759);
  padding: 1em;
  color: #fff;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 20vh;
}
.footer a {
  color: #fff;
}

.fab {
  padding-right: 10px;
}
ul li {
  display: inline-block;
  padding: 1em;
}

/* a {
  color: #000;
  text-decoration: none;
} */
.spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5em;
}

.spinner div {
  padding: 5em;
}

.box {
  border: solid 1px #000;
  margin: 5px;
  padding: 10px;
}

.box .title {
  font-weight: 800;
}

label {
  display: block;
}
.error {
  color: red;
}

.img-box{
  border: 1px solid red;
  display: flex;
  align-content: center;
  padding: 1em;
  box-shadow: 0px 2px 10px #555553;
}

/* h1{
  /* padding: 10p
*/


.hover-grey-section:hover {
  background-color: #f0f0f0; 
}

.custom-navbar {

  border-radius: 10px; 
  
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3); 
  
  margin-top: 20px;
}  





/* --------------------------------------------------- */
.wrapper{
	width: 50%;
	margin: 0 auto;




}

.Container{
    width: 900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.Containe-{
	width: 900px;
	margin: 0 auto;
	display: flex;
	/* justify-items: ; */
	align-items: center;
	


}
.space{
	margin-bottom: 50px;
}

.Container *{

	padding-left: 10px;
	padding-right: 10px;
  }


.cuhkicon{
	padding-top: 15px;
	padding-bottom: 15px;
}

.Container-h{
	width: 900px;
	margin: 0 auto;
	display: flex;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
	/* align-items: center; */
	flex-direction:column;
	
}

/* Songs Page */

.cover {
	width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}


.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}
.cover img {
  width: 100%;
  
  height: auto;
}


li {
  width: 100%;
}


.styled-button {
  background-color: hsl(208, 54%, 73%);
  color: white;
  margin: 10px 0px;
  display: inline-block;
  border: none;
  padding: 2px 10px;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.button-text {
  font-size: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.styled-button:hover {
  background-color: hsl(208, 54%, 92%);
}

.styled-button:hover .button-text {
  background-color: hsl(208, 54%, 92%);
}